import { vars } from '@iheartradio/web.accomplice';
import {
  MenuContent,
  MenuRoot,
  MenuTrigger,
} from '@iheartradio/web.accomplice/menu';
import { Notification } from '@iheartradio/web.accomplice/notification';
import { Text } from '@iheartradio/web.accomplice/text';
import {
  media,
  Player,
  Theme,
  ThemeProvider,
  useMediaQuery,
  useTheme,
  useToast,
} from '@iheartradio/web.companion';
import { isNull } from '@iheartradio/web.utilities';
import { useCallback, useState } from 'react';

import { AuthenticateCTANotification } from '~app/components/cta/authenticate-cta';
import type { CreatePlaylistSubmitCallback as CreateSubmitCallback } from '~app/components/dialogs/create-playlist-dialog';
import {
  type AddToCollectionSubmitCallback as AddSubmitCallback,
  AddToPlaylistSubMenu,
} from '~app/components/menu-items/add-to-playlist-sub-menu';
import { useUser } from '~app/contexts/user';
import {
  ADD_TO_PLAYLIST_AUTHENTICATION_MESSAGE,
  REG_GATE_TRIGGER_TYPES,
} from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';

import { playback } from '../playback';

export function AddToPlaylist() {
  const user = useUser();
  const toast = useToast();
  const { station } = playback.useState();
  const metadata = playback.useMetadata();
  const isLarge = useMediaQuery(media.large);
  const [isFullScreen, setIsFullScreen] = Player.useFullScreen();
  const defaultTheme = useTheme();
  const theme = isFullScreen ? defaultTheme : Theme.Dark;
  // TODO: Refactor to a better solution - ref blur, ref focus, etc.
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleAddSubmit = useCallback<AddSubmitCallback>(data => {
    setMenuOpen(false);
    if (data?.ok) {
      toast(dismiss => (
        <Notification
          actions={[
            {
              color: 'gray',
              onPress: () => setIsFullScreen(false),
              textColor: vars.color.gray600,
              content: 'Go to Playlist',
              href: buildPlaylistUrl(data.collection),
              kind: 'tertiary',
              size: 'large',
            },
          ]}
          kind="success"
          onDismiss={dismiss}
        >
          <Text
            as="p"
            css={{ color: vars.color.gray600 }}
            kind={{ mobile: 'body-4', medium: 'body-3' }}
          >
            Added to {data.collection.name}
          </Text>
        </Notification>
      ));
    }

    if (!data?.ok) {
      toast(dismiss => (
        <Notification kind="error" onDismiss={dismiss}>
          These songs are already added
          <Text kind="body-4">
            We can&apos;t add duplicate songs to a playlist.
          </Text>
        </Notification>
      ));
    }
  }, []);

  const handleCreateSubmit = useCallback<CreateSubmitCallback>(
    (data, closeDialog) => {
      closeDialog();
      setMenuOpen(false);

      if (data.ok) {
        toast(dismiss => (
          <Notification
            actions={[
              {
                color: 'gray',
                onPress: () => setIsFullScreen(false),
                textColor: vars.color.gray600,
                content: 'Go to Playlist',
                href: buildPlaylistUrl(data.collection),
                kind: 'tertiary',
                size: 'large',
              },
            ]}
            kind="success"
            onDismiss={dismiss}
          >
            <Text
              as="p"
              css={{ color: vars.color.gray600 }}
              kind={{ mobile: 'body-4', medium: 'body-3' }}
            >
              Playlist created
            </Text>
          </Notification>
        ));
      }
    },
    [setIsFullScreen, toast],
  );

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const { trackId, id } = metadata.data;

  if (user?.isAnonymous) {
    return (
      <Player.AddToPlaylist
        data-test="add-to-playlist-button"
        onClick={() => {
          toast(dismiss => (
            <AuthenticateCTANotification
              dismiss={dismiss}
              trigger={REG_GATE_TRIGGER_TYPES.ADD_TO_PLAYLIST}
            >
              {ADD_TO_PLAYLIST_AUTHENTICATION_MESSAGE}
            </AuthenticateCTANotification>
          ));
        }}
      />
    );
  }

  // TODO: CHECK THIS!
  return (
    <MenuRoot open={menuOpen}>
      <MenuTrigger asChild>
        <Player.AddToPlaylist
          data-test="add-to-playlist-menu-trigger"
          onClick={() => setMenuOpen(!menuOpen)}
        />
      </MenuTrigger>
      <MenuContent
        align={!isLarge && isFullScreen ? 'end' : 'start'}
        data-test="add-to-playlist-menu"
        onInteractOutside={() => setMenuOpen(false)}
        style={{
          maxHeight: '40dvh',
        }}
      >
        <ThemeProvider value={theme}>
          <AddToPlaylistSubMenu
            onAddSubmit={handleAddSubmit}
            onCreateSubmit={handleCreateSubmit}
            tracks={[Number(trackId ?? id)]}
          />
        </ThemeProvider>
      </MenuContent>
    </MenuRoot>
  );

  // TODO: REMOVE THIS
  // return (
  //   <Menu
  //     align={!isLarge && isFullScreen ? 'end' : 'start'}
  //     data-test="add-to-playlist-menu"
  //     maxHeight="40dvh"
  //     onInteractOutside={() => setMenuOpen(false)}
  //     open={menuOpen}
  //     trigger={
  //       <Player.AddToPlaylist
  //         data-test="add-to-playlist-menu-trigger"
  //         onClick={() => setMenuOpen(!menuOpen)}
  //       />
  //     }
  //   >
  //     <ThemeProvider value={theme}>
  //       <AddToPlaylistSubMenu
  //         onAddSubmit={handleAddSubmit}
  //         onCreateSubmit={handleCreateSubmit}
  //         tracks={[Number(trackId ?? id)]}
  //       />
  //     </ThemeProvider>
  //   </Menu>
  // );
}
