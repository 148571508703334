import { lightDark } from '@iheartradio/web.accomplice';
import type { IconProps } from '@iheartradio/web.accomplice/icon';
import { Link } from '@iheartradio/web.accomplice/link';
import { Box } from '@iheartradio/web.companion';

export type FooterSocialProps = {
  name: string;
  link: string;
  icon: (props: Omit<IconProps, 'children'>) => JSX.Element;
};

export const FooterSocial = ({
  name,
  link,
  icon: IconComponent,
}: FooterSocialProps) => (
  <Box as="li" listStyle="none" paddingRight="$4">
    <Link
      css={{
        display: 'inline-block',
        borderRadius: '$999',
        padding: '$4',
        fontSize: 0,
        transition: 'all 200ms ease',
        outline: {
          default: 'none',
          focus: 'none',
        },
        backgroundColor: {
          active: lightDark('$gray200', '$gray500'),
          hover: lightDark('$gray300', '$gray400'),
        },
      }}
      data-test={name}
      href={link}
      rel="noreferrer"
      target="_blank"
    >
      <IconComponent fill={lightDark('$gray450', '$brandWhite')} size={24} />
    </Link>
  </Box>
);
